@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --background: 0 0% 100%;
  --foreground: 222.2 84% 4.9%;

  --card: 0 0% 100%;
  --card-foreground: 222.2 84% 4.9%;
 
  --popover: 0 0% 100%;
  --popover-foreground: 222.2 84% 4.9%;
 
  --primary: 330 100% 60%;
  --primary-foreground: 210 40% 98%;
 
  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;
 
  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;
 
  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;
 
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 40% 98%;

  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
  --ring: 221.2 83.2% 53.3%;
 
  --radius: 0.5rem;
}

/* Base styles */
body {
  background-color: hsl(var(--background));
  color: hsl(var(--foreground));
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
}

/* Layout */
.container {
  width: 100%;
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

/* Typography */
.text-primary {
  color: hsl(var(--primary));
}

.text-muted {
  color: hsl(var(--muted-foreground));
}

/* Components */
.card {
  background-color: hsl(var(--card));
  border-radius: var(--radius);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.card-content {
  padding: 1.5rem;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius);
  font-weight: 500;
  padding: 0.5rem 1rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  cursor: pointer;
  font-size: 0.875rem;
}

.btn-default {
  background-color: hsl(var(--secondary));
  color: hsl(var(--secondary-foreground));
}

.btn-default:hover {
  background-color: hsl(var(--secondary) / 0.8);
}

.btn-primary {
  background-color: hsl(var(--primary));
  color: hsl(var(--primary-foreground));
}

.btn-primary:hover {
  background-color: hsl(var(--primary) / 0.9);
}

.btn-ghost {
  background-color: transparent;
  color: hsl(var(--foreground));
}

.btn-ghost:hover {
  background-color: hsl(var(--accent));
  color: hsl(var(--accent-foreground));
}

.btn-destructive {
  background-color: hsl(var(--destructive));
  color: hsl(var(--destructive-foreground));
}

.btn-destructive:hover {
  background-color: hsl(var(--destructive) / 0.9);
}

.input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid hsl(var(--border));
  border-radius: var(--radius);
  background-color: hsl(var(--background));
  color: hsl(var(--foreground));
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-gradient {
  background-image: linear-gradient(to bottom, var(--color-pink-50), var(--color-white));
}

.input-icon-wrapper {
  position: relative;
}

.input-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-muted);
}

.select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid hsl(var(--border));
  border-radius: var(--radius);
  background-color: hsl(var(--background));
  color: hsl(var(--foreground));
}

.progress {
  width: 100%;
  height: 0.5rem;
  background-color: hsl(var(--muted));
  border-radius: 9999px;
  overflow: hidden;
}

.progress::-webkit-progress-bar {
  background-color: hsl(var(--muted));
}

.progress::-webkit-progress-value {
  background-color: hsl(var(--primary));
}

.progress::-moz-progress-bar {
  background-color: hsl(var(--primary));
}

/* Icons */
.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.icon-sm {
  width: 1rem;
  height: 1rem;
}

.icon-lg {
  width: 2rem;
  height: 2rem;
}

.icon-primary {
  color: hsl(var(--primary));
}

.icon-muted {
  color: hsl(var(--muted-foreground));
}

/* Animations */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

/* Utilities */
.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.rounded {
  border-radius: var(--radius);
}

.rounded-full {
  border-radius: 9999px;
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Privacy Policy Styles */
.policy-container {
  padding-top: 0; /* Remove top padding */
  padding-bottom: 0; /* Remove bottom padding */
}

.policy-content-wrapper {
  position: relative;
  flex: 1;
  padding-top: 4rem; /* Adjust this value based on your navbar height */
}

.policy-main {
  max-width: 1152px; /* Matches the max-w-6xl class used in the pricing section */
  margin: 0 auto;
  padding: 2rem 1rem; /* Add some vertical padding */
}

.policy-article {
  background-color: hsl(var(--background));
  border-radius: var(--radius);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 2rem;
}

.policy-title {
  font-size: 2.25rem;
  font-weight: 700;
  color: hsl(var(--primary));
  margin-bottom: 1rem;
}

.policy-date {
  font-size: 0.875rem;
  color: hsl(var(--muted-foreground));
  margin-bottom: 2rem;
}

.policy-section {
  margin-bottom: 2rem;
}

.policy-section-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: hsl(var(--foreground));
  margin-bottom: 1rem;
}

.policy-subsection-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: hsl(var(--foreground));
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.policy-text {
  margin-bottom: 1rem;
}

.policy-list {
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.policy-list li {
  margin-bottom: 0.5rem;
}

/* Navbar and Footer adjustments */
.navbar,
.footer {
  padding-left: 0;
  padding-right: 0;
}